import React, { useState } from 'react';
import axios from 'axios';
import './App.css';

const App = () => {
  const [file, setFile] = useState(null);
  const [uploadButtonVisible, setUploadButtonVisible] = useState(true);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    // Validate if the selected file is a JSON file
    if (selectedFile && selectedFile.type === 'application/json') {
      setFile(selectedFile);
      setUploadButtonVisible(false);
    } else {
      setErrorMessage('Please select a valid JSON file.');
    }
  };

  const handleUpload = async () => {
    try {
      if (!file) {
        setErrorMessage('No file selected.');
        return;
      }
  
      // Read the contents of the file
      const fileContent = await file.text();
  
      // Attempt to parse the file content as JSON
      let jsonData;
      try {
        jsonData = JSON.parse(fileContent);
      } catch (jsonError) {
        setErrorMessage('Invalid JSON file.');
        return;
      }

      console.log(jsonData);
  
      // Send a POST request to the server with the JSON data
      const apiUrl = process.env.ITEM_CREATION_URL;
      const response = await axios.post(apiUrl, jsonData);
  
      if (response.status === 200) {
        setSuccessMessage('Items created successfully!');
      } else {
        setErrorMessage(`Error: ${response.status}`);
      }
    } catch (error) {
      setErrorMessage('Error processing the file. ' + error);
    }
  };
  

  return (
    <div className="app-container">
      <h1>Manatee Bay</h1>

      {uploadButtonVisible && (
        <div>
          <input type="file" accept=".json" onChange={handleFileChange} />
        </div>
      )}

      {errorMessage && <div className="error-message">{errorMessage}</div>}
      {successMessage && <div className="success-message">{successMessage}</div>}

      {!uploadButtonVisible && (
        <button className="create-items-button" onClick={handleUpload}>
          Create Items
        </button>
      )}
    </div>
  );
};

export default App;
